
export const environment = {
   production: true,
   PERMISSION_API_BASE_URL: "http://46.101.179.148:8080/",
   APP_BASE_URL: "http://167.99.135.173",
   APP_BFF_URL: "http://167.99.135.173:4001",
   CLIENT_NAME: "avangarde-portalv2",
   API_LOGIN_REDIRECT_URL: "http://46.101.179.148:8080/login",
   APP_DASHBOARD_URL: "/dashboard-component",
   API_LOGOUT_REDIRECT_URL: "http://46.101.179.148:8080/logout",
   API_LOGIN_CLIENT_REDIRECT_URL: "http://46.101.179.148:8080/loginWithClient",
   PMANAGER_BASE_URL: "http://167.99.135.173:4202"
};
